<template>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto">
        <div class="mt-10">
            <a href="/">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Volver a la pagina principal
                </button>
            </a>
        </div>
        <div>
            <h1 class="text-2xl font-bold text-center">Compras</h1>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Fecha
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Estado
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Detalles
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in compras" :key="c" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ c.id }}
                            </th>
                            <td class="px-6 py-4">
                                {{ c.fecha }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.total }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.estado }}
                            </td>
                            <td class="px-6 py-4">
                                <button @click="verDetalle(c.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Detalles
                                </button>
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <tr :id="'detalle_'+c.id" v-for="d in c.detalles" :key="d" class="hidden">
                                        <td class="px-6 py-4">
                                            {{ d.producto }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.cantidad }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.precio }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.subtotal }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
//import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
    name: "PerfilUsuario",
    el: "#app",
    //components:{Swal},
    props: {
      ip_prop: String,
    },
    data() {
        return {
            compras: []
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('get_compras',1);

    },
    methods:{
        verDetalle(id){
            let detalle = document.getElementById('detalle_'+id);
            if(detalle.classList.contains('hidden')){
                detalle.classList.remove('hidden');
            }else{
                detalle.classList.add('hidden');
            }
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    this.compras = result.data['compras'];
                    break;
            }
        }
    }
}
</script>

