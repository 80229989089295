<template>
    <div class="flex flex-col mt-10">
        <div class="flex flex-row justify-between items-center">
            <a href="/" class="ml-10">
                <button class="bg-red-500 text-white px-4 py-2 rounded-lg flex flex-row">
                    <ArrowLongLeftIcon class="w-[25px] mr-3"></ArrowLongLeftIcon>Regresar
                </button>
            </a>
        </div>
        <h1 class="text-2xl font-bold mb-4">Catalogo</h1>
        <div class="p-4">
            <div class="mb-8">
            <div class="text-4xl bg-white w-[300px] my-6 p-4 shadow-2xl -rotate-[2deg] mx-auto border-2 border-black">{{categoria}}</div>
                <div class="flex flex-wrap gap-[30%] items-center justify-center">
                    <div class="relative rounded-lg overflow-hidden p-4 w-[500px]" 
                        v-for="producto,index in productos" 
                        :key="index">
                        <div class="flex flex-col mt-16 cursor-pointer items-center" @click="handleAddToCart({id:producto.id,name:producto.titulo,price:producto.precio})">
                            <h3 class="text-xl font-semibold mb-2">{{ producto.titulo }}</h3>
                            <div class="bg-white -rotate-[3deg] shadow-2xl border-2 border-black w-[250px]">
                                <img :src="'static/productos/'+producto.imagen" alt="Imagen del producto" class="w-[250px] h-[220px] object-cover mb-4" />
                                <div class="absolute bottom-2 right-2 bg-red-500 text-white font-bold px-2 py-1 rounded-full price-tag text-4xl">
                                    {{ producto.precio }}
                                </div>
                            </div>
                            <h3 class="text-xl mt-4">{{ producto.descripcion }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { ArrowLongLeftIcon } from '@heroicons/vue/24/solid';
  export default {
    components: {
      ArrowLongLeftIcon
    },
    name: 'CatalogoAko',
    data() {
      return {
        productos: [],
        categoria: ''
      };
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.categoria = urlParams.get('cat');
        let form_data = new FormData();
        form_data.append('categoria', this.categoria);
        window.load_response('/get_productos_categoria',form_data,1);
        window.select_funcion = this.select_funcion;
    },
    methods: {
        handleAddToCart(product) {
            window.addToCart(product);
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.productos = response.data.productos;
                    break;
            }

        }
    }
  }
  </script>