<template>
    <div class="flex flex-col text-white">
        <div class="py-2"></div>
        <div class="text-[40px]">Colaciones Empresariales</div>
        <div class="text-[20px] mt-4">
            Realizamos todo tipo de colaciones que necesites para tus cumpleaños,<br>
            eventos, personal en terreno, celebraciones y mucho mas!<br>
            todo acuerdo a tus requisitos y necesidades!
        </div>
        <div class="flex flex-row space-x-16 mt-4 justify-center">
            <div v-for="(c,index) in colaciones" :key="c" class="flex flex-col items-center w-[240px]">
                <div class="flex w-[250px] h-[250px] bg-white justify-center items-center">
                    <img :src="'static'+c.path" :alt="'categoria'" class="mb-4 w-[180px] object-cover"/>
                </div>
                <div class="text-2xl w-full">
                    {{ c.titulo }}
                </div>
                <div class="font-bold text-center w-full">
                    <div v-for="p in c.productos" :key="p" class="mx-auto">
                        {{ p.nombre }} - ${{ p.precio }}
                    </div>
                </div>
                <div v-if="c.precio_oferta" class="text-center font-bold text-lg"><span v-if="index==0" class="line-through">${{c.precio}}</span> ${{c.precio_oferta}} + Iva</div>
                <div v-else class="text-center font-bold text-lg"> ${{c.precio}} + Iva</div>
                <button v-if="c.precio_oferta" @click="handleAddToCart({id:c.id,name:c.nombre,price:c.precio_oferta})" class="w-[200px] h-[50px] bg-cyan-400 text-white text-[20px] rounded-xl">Comprar ahora</button>
                <button v-else @click="handleAddToCart({id:c.id,name:c.nombre,price:c.precio})" class="w-[200px] h-[50px] bg-cyan-400 text-white text-[20px] rounded-xl">Comprar ahora</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Pagina5Home",
    props: {
        colaciones: Object
    },
    data() {
        return {
            
        };
    },
    methods: {
        handleAddToCart(product) {
            window.addToCart(product);
        }   
    }
}
</script>