<template>
    <div>
      <div class="cart-button z-50" @click="toggleCart">
        🛒 ({{ cartItems.length }})
      </div>
      <div v-if="isCartVisible" class="cart-popup">
        <ShoppingCart :cartItems="cartItems" @remove-from-cart="removeFromCart" />
      </div>
    </div>
  </template>
  
  <script>
  import ShoppingCart from './ShoppingCart.vue';
  
  export default {
    components: { ShoppingCart },
    props: ['cartItems'],
    data() {
      return {
        isCartVisible: false,
      };
    },
    methods: {
      toggleCart() {
        this.isCartVisible = !this.isCartVisible;
      },
      removeFromCart(product) {
        this.$emit('remove-from-cart', product);
      }
    }
  }
  </script>
  
  <style scoped>
  .cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff6600;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .cart-popup {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 10px;
  }
  </style>
  