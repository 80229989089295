<template>
    
    <div class="/*fixed top-0 w-full z-50*/ flex flex-row h-[150px] bg-[#0000001a] text-white text-[32px] justify-between p-4">
        <div class="w-[5%]"></div>
        <div class="flex items-center">
            <img :src="logoInicio" alt="Confitel" class="h-[54px] w-[208px]"/>
        </div>
        <div class="hidden md:flex flex-grow items-center justify-around">
            <p class="cursor-pointer">
                <a href="/">
                    Inicio
                </a>
            </p>
            <p @click="scrollToSection('catalogo')" class="cursor-pointer">Productos</p>
            <p>
                <a href="/contacto">
                    Contacto
                </a>
            </p>
            <p>
                <a href="/login">
                    Ingreso/Registro
                </a>
            </p>
            <div class="flex items-center">
                <input type="text" class="focus-visible:border-0 w-[250px] h-[50px] text-[24px] text-center shadow-2xl bg-[#ffc400] text-white placeholder-white" placeholder="Buscar..."/>
                <div class="flex w-[70px] h-[50px] bg-[#e60000] shadow-2xl items-center justify-center">
                    <MagnifyingGlassIcon class="w-[30px]"></MagnifyingGlassIcon>
                </div>
            </div>
        </div>
        <div class="md:hidden flex items-center">
            <button @click="toggleMenu" class="text-white">
                <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
            </button>
        </div>
    </div>
    <div v-if="menuOpen" class="md:hidden flex flex-col items-center bg-[#0000001a] text-white text-[24px]">
        <p class="py-2">Inicio</p>
        <p class="py-2">Productos</p>
        <p class="py-2">
            <a href="/contacto">
                Contacto
            </a>
        </p>
        <p class="py-2">
            <a href="/login">
                Ingreso/Registro
            </a>
        </p>
        <div class="flex flex-col items-center py-2">
            <input type="text" class="focus-visible:border-0 w-[250px] h-[50px] text-[24px] text-center shadow-2xl bg-[#ffc400] text-white placeholder-white" placeholder="Buscar..."/>
            <div class="flex w-[70px] h-[50px] bg-[#e60000] shadow-2xl items-center justify-center mt-2">
                <MagnifyingGlassIcon class="w-[30px]"></MagnifyingGlassIcon>
            </div>
        </div>
    </div>
    <div class="text-4xl justify-center items-center mt-10">
        Sitio en construcción...
    </div>
    <div id="inicio" class="flex flex-col md:flex-row justify-end mt-16 px-4 md:px-0">
        <div class="flex flex-col text-white w-full md:w-[50%] text-start">
            <div class="text-[40px] md:text-[70px] font-bold mt-14 leading-tight md:leading-[70px]">
                Bienvenidos a la esquina<br>
                más dulce de Calama
            </div>
            <div class="text-[20px] md:text-[30px] leading-[25px] md:leading-[35px] mt-6 ml-0 md:ml-6">
                Tu paraíso de golosinas donde encontrarás una amplia<br>
                variedad de caramelos, chocolates y malvas irresistibles.<br>
            </div>
            <div class="text-[20px] md:text-[30px] leading-[25px] md:leading-[35px] mt-4 ml-0 md:ml-6">
                ¡Endulza tu día con nosotros!
            </div>
            <div class="flex flex-col md:flex-row ml-0 md:ml-6 space-y-4 md:space-y-0 md:space-x-6 mt-6">
                <div>
                    <button class="w-full md:w-[200px] h-[50px] md:h-[70px] bg-[#e60000] text-white text-[20px] md:text-[24px] rounded-lg">Contáctanos</button>
                </div>
                <div>
                    <button class="w-full md:w-[200px] h-[50px] md:h-[70px] bg-transparent text-white text-[20px] md:text-[24px] border-white border-2 rounded-lg">Servicios</button>
                </div>
            </div>
        </div>
        <div class="w-full md:w-[10%]"></div>
        <div class="flex justify-end mt-8 md:mt-0 px-0">
            <img :src="esquinaConfitel" alt="Esquina Confitel" class="h-[300px] md:h-[600px]"/>
        </div>
    </div>
    <div class="bg-white flex flex-col">
        <div class="flex flex-col md:flex-row space-x-0 md:space-x-20 justify-center mt-10">
            <div class="text-center md:text-start">
                <div class="font-bold text-[24px] md:text-[40px] mx-4">
                    ¡Ofertas, promociones <br>
                    y Descuentazos! <br>
                    No los dejes pasar
                </div>
                <div class="ml-0 md:ml-10 text-[16px] md:text-[20px] mx-4">
                    ¡No te pierdas nuestras increíbles ofertas!<br>
                    Descubre las mejores promociones y <br>
                    de productos.<br>
                    ¡Compra ahora y ahorra más!
                </div>
                <a href="https://wa.link/qqm57y">
                    <div class="ml-0 md:ml-10 mt-5 flex flex-row justify-center md:justify-start space-x-4">
                        <button class="w-3/4 md:w-[180px] h-[40px] md:h-[50px] bg-[#00b726] text-white text-[16px] md:text-[20px] rounded-lg">Ver ofertas</button>
                        <img :src="wsp" alt="wsp" class="w-[50px] md:w-[62px] h-[56px] md:h-[68px]"/>
                    </div>
                </a>
            </div>
            <div class="flex justify-center mt-10 md:mt-0">
                <img :src="ofertas" alt="Ofertas" class="w-[300px] md:w-[500px] h-[300px] md:h-[500px]"/>
            </div>
        </div>
        <div class="mt-10 px-5">
            <swiper
                class="swiper"
                :modules="modules"
                :space-between="30"
                :slides-per-view="3"
                :autoplay="{ delay: 2500,disableOnInteraction: false }"
                :pagination="{ clickable: true }"
                :breakpoints="{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: { 
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: { 
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }"
            >
                <swiper-slide v-for="(product, index) in products" :key="index" class="slide">
                    <div class="bg-white p-4 h-[350px]">
                        <img :src="'static/productos/'+product.imagen" :alt="product.titulo" class="mx-auto mb-4 w-[200px] h-[200px]"/>
                        <h3 class="text-xl font-bold mb-2">{{ product.titulo }}</h3>
                        <p class="text-gray-700">{{ product.descripcion }}</p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <div class="p-8 text-center">
        <h2 class="text-white text-4xl font-bold mb-4">Incorporación Reciente</h2>
        <p class="text-white text-lg mb-8">
            Nos complace anunciar la llegada de nuestras nuevas <br>
            incorporaciones, cuidadosamente seleccionadas para ofrecerte<br>
            la mejor calidad y rendimiento. Estos productos han sido diseñados<br>
            para satisfacer las expectativas más exigentes,<br>
            combinando innovación, durabilidad y un diseño excepcional.
        </p>
        <div class="flex my-10 items-center">
            <swiper
                class="swiper w-full items-center justify-center"
                :modules="modules"
                :centeredSlides="true"
                :space-between="30"
                :slides-per-view="3"
                :autoplay="{ delay: 2500,disableOnInteraction: false }"
                :pagination="{ clickable: true }"
                :breakpoints="{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: { 
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: { 
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }"
            >
                <swiper-slide v-for="(product, index) in productsIncorporacion" :key="index" class="slide z-10">
                    <div class="bg-white p-4 h-[250px]">
                        <img :src="'static/productos/'+product.imagen" :alt="product.titulo" class="mx-auto mb-4 w-[200px] h-[200px]"/>
                    </div>
                    <div class="my-6 text-white">
                        <h3 class="text-xl font-bold mb-2">{{ product.titulo }}</h3>
                        <p>{{ product.descripcion }}</p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
    <Pagina4 id="catalogo"></Pagina4>
    <Pagina5
        :colaciones="colaciones"
    ></Pagina5>
    <Pagina6
        :canastas="canastas"
    ></Pagina6>
    <Footer></Footer>
    
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import logoInicio from "@/assets/inicio_logo.png";
import esquinaConfitel from "@/assets/esquinaConfitel.png";
import ofertas from "@/assets/oferta.jpg";
import wsp from "@/assets/wsp.png";
import { MagnifyingGlassIcon} from '@heroicons/vue/24/solid';
import Footer from "@/components/Home/Footer.vue";
import Pagina4 from "@/components/Home/Pagina4.vue";
import Pagina5 from "@/components/Home/Pagina5.vue";
import Pagina6 from "@/components/Home/Pagina6.vue";
export default {
    name: "HomeConfitel",
    el: "#app",
    components: {
        MagnifyingGlassIcon,
        Swiper,
        SwiperSlide,
        Pagina4,
        Pagina5,
        Pagina6,
        Footer
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('/get_productos',1);
        window.load_axios('/get_colaciones_home',2);
        window.load_axios('/get_canastas_home',3);
    },
    data() {
        return {
            colaciones: {},
            canastas: {},
            wsp: wsp,
            ofertas: ofertas,
            logoInicio: logoInicio,
            esquinaConfitel: esquinaConfitel,
            menuOpen: false,
            products: [],
            productsIncorporacion: [],
            modules: [Autoplay, Pagination],
            swiperOptions: {                
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                el: '.swiper-pagination',
                clickable: true
                },
                breakpoints: {
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50
                }
                }
            }
        };
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.products = response.data.productos;
                    this.productsIncorporacion = response.data.productsIncorporacion;
                    break;
                case 2:
                    this.colaciones = response.data.colaciones;
                    break;
                case 3:
                    this.canastas = response.data.canastas;
                    break;
            }
        },
    },
}
</script>