<template>
  <div>
    <h2>Carrito de Compras</h2>
    <div v-if="cartItems.length === 0">Tu carrito está vacío</div>
    <ul>
      <li v-for="item in cartItems" :key="item.id">
        {{ item.name }} - {{ formatCurrency(item.price) }} x {{ item.quantity }}
        <button @click="removeFromCart(item)">Eliminar</button>
      </li>
    </ul>
    <p>Total: {{ formatCurrency(cartTotal) }}</p>
  </div>
</template>

<script>
export default {
  props: ['cartItems'],
  computed: {
    cartTotal() {
      return this.cartItems.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0);
    }
  },
  methods: {
    removeFromCart(item) {
      this.$emit('remove-from-cart', item);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
      }).format(value);
    }
  }
}
</script>