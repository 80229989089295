<template>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto">
        <div class="mt-10">
            <a href="/">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Volver a la pagina principal
                </button>
            </a>
        </div>
        <div>
            <h1 class="text-2xl font-bold text-center">Compras</h1>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Fecha
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Estado
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Detalles
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in compras" :key="c" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ c.id }}
                            </th>
                            <td class="px-6 py-4">
                                {{ c.usuario }}<br>
                                {{ c.correo }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.fecha }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.total }}
                            </td>
                            <td class="px-6 py-4">
                                {{ c.estado }}
                            </td>
                            <td class="px-6 py-4">
                                <button @click="verDetalle(c.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Detalles
                                </button>
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <tr :id="'detalle_'+c.id" v-for="d in c.detalles" :key="d" class="hidden">
                                        <td class="px-6 py-4">
                                            {{ d.producto }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.cantidad }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.precio }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ d.subtotal }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="px-6 py-4">
                                <div class="flex flex-col">
                                    <div clas="flex flex-col">
                                        <div>
                                            Estado
                                        </div>
                                        <select :id="'estado_'+c.id" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value='En proceso'>En proceso</option>
                                            <option value='En camino'>En camino</option>
                                            <option value='Completado'>Completado</option>
                                        </select>
                                    </div>
                                    <div class="mt-2">
                                        <button @click="actualizarEstado(c.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Actualizar
                                        </button>
                                    </div>
                                    <div class="mt-2">
                                        <button @click="eliminarCompra(c.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { Swal } from 'sweetalert2/dist/sweetalert2';

//import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
    name: "PerfilUsuario",
    el: "#app",
    //components:{Swal},
    props: {
      ip_prop: String,
    },
    data() {
        return {
            compras: [],
            estado: ''
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
        window.load_axios('get_compras_admin',1);
    },
    methods:{
        actualizarEstado(id){
            let estado = document.getElementById('estado_'+id).value;
            let formData = new FormData();
            formData.append('id',id);
            formData.append('estado',estado);
            window.load_response('actualizar_estado_compra',formData,2);
        },
        eliminarCompra(id){
            Swal.fire({
                title: '¿Estas seguro?',
                text: "No podras revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();
                    formData.append('id',id);
                    window.load_response('eliminar_compra',formData,3);
                }
            });
        },
        verDetalle(id){
            let detalle = document.getElementById('detalle_'+id);
            if(detalle.classList.contains('hidden')){
                detalle.classList.remove('hidden');
            }else{
                detalle.classList.add('hidden');
            }
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    this.compras = result.data['compras'];
                    break;
                case 2:
                    Swal.fire({
                        title: 'Exito',
                        text: 'Estado actualizado',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    break;
                case 3:
                    Swal.fire({
                        title: 'Exito',
                        text: 'Compra eliminada',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    break;
            }
        }
    }
}
</script>

