<template>
  <router-view />
  <CartButton :cartItems="cartItems" @remove-from-cart="removeFromCart" />
  <footer id="foorter_version" class="fixed bottom-0 text-right w-full px-5 z-50">
    <p>Versión: 0.3.6 Confitel</p>
  </footer>
</template>

<script>
import CartButton from '@/components/Carrito/CartButton.vue';
import axios from "axios";
//import NavBar from '@/components/NavBar.vue'
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
export default {
  components: { CartButton },
  name: 'App',
  /*variables globales*/
  //components:{NavBar},
  data(){
    return {
        csrfToken:'',
        csrfmiddlewaretoken:'',
        nav:false,
        cartItems: []
    }
  },
  mounted(){
    window.load_axios = this.load_axios;
    window.load_response = this.load_response;
    window.load_response_file = this.load_response_file;
    window.addToCart = this.addToCart;
    this.csrfmiddlewaretoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
    /* const queryString = window.location.pathname;
    if(queryString!='/'){
      this.nav=true;
    }else{
      axios.get(this.link + "chequeo_sesion", {
          mode: "no-cors",
      })
      .then((response) => {
        if(response.data['user']!='anonymous'){
            location.href="/home";
        }
      });
    } */
  },
  created() {
    this.loadCart();
  },
  methods:{
    addToCart(product) {
      const item = this.cartItems.find(item => item.id === product.id);
      if (item) {
        item.quantity++;
      } else {
        this.cartItems.push({ ...product, quantity: 1 });
      }
      this.saveCart();
    },
    removeFromCart(product) {
      const index = this.cartItems.findIndex(item => item.id === product.id);
      if (index !== -1) {
        this.cartItems.splice(index, 1);
      }
      this.saveCart();
    },
    saveCart() {
      localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    },
    loadCart() {
      const savedCart = localStorage.getItem('cartItems');
      if (savedCart) {
        this.cartItems = JSON.parse(savedCart);
      }
    },
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Check if the cookie name matches the desired format
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },
    /*
      * Funcion que carga la respuesta de una peticion axios
      */
      load_axios(url, numero_funcion) {
        axios
          .get(url, {
            mode: "no-cors",
          })
          .then((response) => {
            window.select_funcion(response, numero_funcion);
          });
      },
      /**
       * Funcion que ejecuta un post por axios y carga la promesa (respuesta)
       */
      load_response(url, selectedItem, numero_funcion) {
        let response;
        response = axios.post(url, selectedItem,{
          headers: {'X-CSRFToken': this.csrfmiddlewaretoken,
          'HTTP_X_CSRFTOKEN':this.csrfmiddlewaretoken
        },
        });
        response.then(function (result) {
          window.select_funcion(result, numero_funcion);
        });
      },
      load_response_file(url, selectedItem, numero_funcion) {
        let response;
        response = axios.post(url, selectedItem,{
          headers: {'X-CSRFToken': this.csrfmiddlewaretoken,
          'HTTP_X_CSRFTOKEN':this.csrfmiddlewaretoken,
          'Content-Type': 'multipart/form-data',
        },
        });
        response.then(function (result) {
          window.select_funcion(result, numero_funcion);
        });
      },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style scoped>
footer{
    /*font-family: "Plus Jakarta Sans", sans-serif;*/
    color: #4f5f6e;
}
</style>
