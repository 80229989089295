<template>
    <div class="flex flex-row p-10">
        <a href="/">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Volver al inicio
            </button>
        </a>
        <button @click="open_modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Agregar Producto
        </button>
    </div>

    <div class="flex flex-col">
        <p class="text-center font-medium text-lg mt-2">Carga masiva</p>
        <div class="flex flex-col">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
            <input id="file_input" type="file" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
        </div>
        <div class="py-5"></div>
        <button type="button" id="button-ok" @click="cargar_productos()"
            class="bg-[#30E68B] rounded p-2 rounded text-white w-[180px] font-[700] text-[12px] mx-auto">
            <div>Cargar productos catalogo</div>
        </button>
    </div>
    
    <div class="overflow-x-auto">
        <h1 class="text-2xl font-bold mb-4">Productos del Carrusel</h1>
        <table class="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead class="bg-blue-700 text-white">
            <tr>
                <th class="py-2 px-4">Título</th>
                <th class="py-2 px-4">Path</th>
                <th class="py-2 px-4">Descripción</th>
                <th class="py-2 px-4">Categoria</th>
                <th class="py-2 px-4">Precio</th>
                <th class="py-2 px-4">Tipo</th>
                <th class="py-2 px-4">Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="producto in productos" :key="producto.id" class="border-b">
                <td class="py-2 px-4">{{ producto.titulo }}</td>
                <td class="py-2 px-4">{{ producto.imagen }}</td>
                <td class="py-2 px-4">{{ producto.descripcion }}</td>
                <td class="py-2 px-4">{{ producto.categoria }}</td>
                <td class="py-2 px-4">{{ producto.precio }}</td>
                <td v-if="producto.carrusel==0" class="py-2 px-4">
                    Carrusel
                </td>
                <td v-if="producto.carrusel==1" class="py-2 px-4">
                    Incorporaciones recientes
                </td>
                <td v-if="producto.carrusel==2" class="py-2 px-4">
                    Catalogo
                </td>
                <td class="py-2 px-4 flex space-x-2">
                    <button @click="open_edit_modal(producto)" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-3 rounded">Editar</button>
                    <button @click="deleteProducto(producto.id)" class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded">Eliminar</button>
                </td>
            </tr>
            <tr v-for="producto in productsIncorporacion" :key="producto.id" class="border-b">
                <td class="py-2 px-4">{{ producto.titulo }}</td>
                <td class="py-2 px-4">{{ producto.imagen }}</td>
                <td class="py-2 px-4">{{ producto.descripcion }}</td>
                <td class="py-2 px-4">{{ producto.categoria }}</td>
                <td class="py-2 px-4">{{ producto.precio }}</td>
                <td v-if="producto.carrusel==0" class="py-2 px-4">
                    Carrusel
                </td>
                <td v-if="producto.carrusel==1" class="py-2 px-4">
                    Incorporaciones recientes
                </td>
                <td v-if="producto.carrusel==2" class="py-2 px-4">
                    Catalogo
                </td>
                <td class="py-2 px-4 flex space-x-2">
                    <button @click="open_edit_modal(producto)" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-3 rounded">Editar</button>
                    <button @click="deleteProducto(producto.id)" class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded">Eliminar</button>
                </td>
            </tr>
            <tr v-for="producto in productsCatalogo" :key="producto.id" class="border-b">
                <td class="py-2 px-4">{{ producto.titulo }}</td>
                <td class="py-2 px-4">{{ producto.imagen }}</td>
                <td class="py-2 px-4">{{ producto.descripcion }}</td>
                <td class="py-2 px-4">{{ producto.categoria }}</td>
                <td class="py-2 px-4">{{ producto.precio }}</td>
                <td v-if="producto.carrusel==0" class="py-2 px-4">
                    Carrusel
                </td>
                <td v-if="producto.carrusel==1" class="py-2 px-4">
                    Incorporaciones recientes
                </td>
                <td v-if="producto.carrusel==2" class="py-2 px-4">
                    Catalogo
                </td>
                <td class="py-2 px-4 flex space-x-2">
                    <button @click="open_edit_modal(producto)" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-3 rounded">Editar</button>
                    <button @click="deleteProducto(producto.id)" class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded">Eliminar</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div id="modal_agregar" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-2xl max-h-full mx-auto mt-20">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Agregar producto
                    </h3>
                    <button @click="close_modal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <label for="input_nombre" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>
                    <input v-model="titulo" type="text" id="input_nombre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <label for="input_cantidad" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descripcion</label>
                    <textarea v-model="descripcion" type="text" id="input_cantidad" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <label for="imagen" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Imagen</label>
                    <select v-model="imagen" id="imagen" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option v-for="i in nombre_imagenes" :key="i" :value="i">{{i}}</option>
                    </select>
                    <div>
                        <img :src="'static/productos/'+imagen" :alt="'preview'" class="mx-auto mb-4 w-[250px] h-[250px]"/>
                    </div>
                    <select v-model="carrusel" id="carrusel" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value=0>Carrusel</option>
                        <option value=1>Incorporaciones recientes</option>
                        <option value=2>Catalogo</option>
                    </select>
                    <label v-if="carrusel==2" for="input_categoria" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria</label>
                    <input v-if="carrusel==2" v-model="categoria" type="text" id="input_categoria" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <label v-if="carrusel==2" for="input_precio" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Precio</label>
                    <input v-if="carrusel==2" v-model="precio" type="text" id="input_precio" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>
                <!-- Modal footer -->
                <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button id="btn_editar" @click="editProducto" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Editar</button>
                    <button id="btn_agregar" @click="addProducto" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Agregar</button>
                    <button @click="close_modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import Swal from "sweetalert2";
  export default {
    data() {
      return {
        titulo:'',
        descripcion:'',
        imagen:'',
        categoria:'',
        carrusel:1,
        productos: [],
        productsIncorporacion: [],
        productsCatalogo: [],
        imagenes: [],
        precio: 0,
      };
    },
    mounted() {
      window.load_axios('/get_productos',1);
      window.select_funcion = this.select_funcion;
    },
    methods: {
        cargar_productos(){
            let formData = new FormData();
            formData.append('file', document.getElementById('file_input').files[0]);
            window.load_response('/cargar_productos',formData,5);
        },
        clean_vars(){
            this.titulo='';
            this.descripcion='';
            this.imagen='';
            this.categoria='';
            this.id=0;
            this.precio=0;
        },
        open_edit_modal(producto){
            this.id = producto.id;
            this.titulo = producto.titulo;
            this.descripcion = producto.descripcion;
            this.imagen = producto.imagen;
            this.carrusel = producto.carrusel;
            this.categoria = producto.categoria;
            this.precio = producto.precio;
            document.getElementById('modal_agregar').classList.remove('hidden');
            document.getElementById('btn_agregar').classList.add('hidden');
            document.getElementById('btn_editar').classList.remove('hidden');
        },
        open_modal(){
            this.clean_vars();
            document.getElementById('modal_agregar').classList.remove('hidden');
            document.getElementById('btn_agregar').classList.remove('hidden');
            document.getElementById('btn_editar').classList.add('hidden');
        },
        close_modal(){
            document.getElementById('modal_agregar').classList.add('hidden');
        },
        addProducto() {
            let formData = new FormData();
            formData.append('titulo', this.titulo);
            formData.append('descripcion', this.descripcion);
            formData.append('path', this.imagen);
            formData.append('carrusel', this.carrusel);
            formData.append('precio', this.precio);
            if(this.carrusel==2){
                formData.append('categoria', this.categoria);
            }else{
                formData.append('categoria', false);
            }
            window.load_response('/add_producto',formData,2);
        },
        deleteProducto(id) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "Quieres borrar este producto?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, bórralo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();
                    formData.append('id', id);
                    window.load_response('/delete_producto',formData,3);
                }
            });
        },
        editProducto() {
            let formData = new FormData();
            formData.append('id', this.id);
            formData.append('titulo', this.titulo);
            formData.append('descripcion', this.descripcion);
            formData.append('path', this.imagen);
            formData.append('carrusel', this.carrusel);
            formData.append('precio', this.precio);
            if(this.carrusel==2){
                formData.append('categoria', this.categoria);
            }else{
                formData.append('categoria', false);
            }
            window.load_response('/update_producto',formData,4);
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.productos = response.data.productos;
                    this.productsIncorporacion = response.data.productsIncorporacion;
                    this.productsCatalogo = response.data.productsCatalogo;
                    this.nombre_imagenes = response.data.nombre_imagenes;
                    break;
                case 2:
                    Swal.fire({
                        title: 'Producto agregado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    this.close_modal();
                    //add to array
                    this.productos.push({
                        id: response.data.id,
                        titulo: this.titulo,
                        descripcion: this.descripcion,
                        imagen: this.imagen,
                        carrusel: this.carrusel,
                        categoria: this.categoria
                    });
                    window.load_axios('/get_productos',1);
                    break;
                case 3:
                    Swal.fire({
                        title: 'Producto eliminado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    //delete from array
                    for (let i = 0; i < this.productos.length; i++) {
                        if(this.productos[i].id==response.data.id){
                            this.productos.splice(i,1);
                            break;
                        }
                    }
                    window.load_axios('/get_productos',1);
                    break;
                case 4:
                    Swal.fire({
                        title: 'Producto editado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    this.close_modal();
                    for (let i = 0; i < this.productos.length; i++) {
                        if(this.productos[i].id==this.id){
                            this.productos[i].titulo = this.titulo;
                            this.productos[i].descripcion = this.descripcion;
                            this.productos[i].imagen = this.imagen;
                            this.productos[i].carrusel = this.carrusel;
                            this.productos[i].categoria = this.categoria;
                            break;
                        }
                    }
                    window.load_axios('/get_productos',1);
                    break;
                case 5:
                    Swal.fire({
                        title: 'Productos cargados',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    window.load_axios('/get_productos',1);
                    break;
            }

        }
    }
  }
  </script>
  