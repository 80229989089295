<template>
    <div class="flex flex-col space-y-5 w-[80%] mx-auto">
        <div class="mt-10">
            <a href="/">
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Volver a la pagina principal
                </button>
            </a>
        </div>
        <h1 class="text-2xl font-bold text-center">Contacto</h1>
        <input v-model="nombre" type="text" class="w-full p-2 border border-gray-300 rounded-lg" placeholder="Nombre">
        <input v-model="correo" type="text" class="w-full p-2 border border-gray-300 rounded-lg" placeholder="Correo">
        <textarea v-model="mensaje" class="w-full p-2 border border-gray-300 rounded-lg" placeholder="Mensaje"></textarea>
        <button @click="enviarMensaje" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Enviar
        </button>
    </div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
    name: "PerfilUsuario",
    el: "#app",
    props: {
      ip_prop: String,
    },
    data() {
        return {
            nombre:'',
            correo:'',
            mensaje:''
        }
    },
    mounted(){
        window.select_funcion = this.select_funcion;
    },
    methods:{
        enviarMensaje(){
            if(this.nombre=='' || this.correo=='' || this.mensaje==''){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debes llenar todos los campos!',
                });
            }else{
                //limitar el mensaje a 500 caracteres
                if(this.mensaje.length>500){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'El mensaje no puede tener mas de 500 caracteres!',
                    });
                    return;
                }
                let formData = new FormData();
                formData.append('nombre',this.nombre);
                formData.append('correo',this.correo);
                formData.append('mensaje',this.mensaje);
                window.load_response('enviar_mensaje',formData,1);
            }
        },
        select_funcion(result, numero_funcion) {
            switch(numero_funcion){
                case 1:
                    Swal.fire({
                        title: 'Exito',
                        text: 'Mensaje enviado',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    this.nombre = '';
                    this.correo = '';
                    this.mensaje = '';
                    break;
            }
        }
    }
}
</script>

